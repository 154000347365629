/* The agreeTerm */
.agreeTerm {
  display: inline;
  position: relative;
  padding-left: 35px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/* Hide the browser's default checkbox */
.agreeTerm input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #F7F9FB;
  border: 1px solid #CCD6DD;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.agreeTerm:hover input ~ .checkmark {
  background-color: #F7F9FB;
}

/* When the checkbox is checked, add a blue background */
.agreeTerm input:checked ~ .checkmark {
  background-color: #01A4CE;
  border: 1px solid #01A4CE;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.agreeTerm input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.agreeTerm .checkmark:after {
  left: 9px;
  top: 2px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.fleetname .fleetname-content {
  font-size: 20px;
  font-weight: 700;
  color: #B38806;

  margin-bottom: 0.5rem;
}

.fleetname p {
  font-size: 16px;
  color: #4A4A4A;
}

  /* The policy */
.policy {
  display: inline;
  position: relative;
  cursor: pointer;
  
  font-size: 14px;
  color: #4A4A4A;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.textRighToLeft{
  text-align: right;
}
