.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* padding: 10px 20px 0 20px; */
}

.banner.top {
    padding-bottom: 20px;
}

.banner.bottom {
    padding-top: 20px;
}

.banner .banner_carousel {
    border-radius: 5px;
}

.banner .img_banner {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}