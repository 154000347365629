:root {
  --main-color: #b38807;
  --mobile-bg-color: #252830; 
} 

.header-wrapper {
  display: flex;
  justify-content: center;

  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px;

  background-color: #ffffff;
  color: #4A4A4A;

  border-bottom: 2px solid #CCD6DD;

  z-index: 1000;
}

.header-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 20px;
  width: 500px;
}

.header-box .header-signup {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: auto;
}

.header-signup a img {
  max-width: 135px;
  height: auto;
}

.header-wrapper .current-lang {
  font-size: 26px;
  font-weight: 500; 
}

.header-wrapper .current-lang img {
  width: 30px;
  height: 30px;
  margin: 0 10px; 
}

.header-wrapper .header-box .header-signup .switch-language {
  display: flex;
}

.header-wrapper .header-box .header-signup .switch-language .dropdown {
  margin-left: 0.5rem;
}

.header-wrapper .header-box .header-signup .switch-language .dropdown-menu {
  border-radius: 5px;
  /* left: unset; */
  /* right: 0;  */
}

.header-wrapper .switch-language .dropdown-menu li a {
  padding-top: 6px;
  padding-bottom: 6px; 
}

.header-wrapper .switch-language .dropdown-menu li a:hover {
  color: var(--main-color);
  background-color: #ffffff; }

.header-wrapper .switch-language #switch-lang-dropdown, #switch-country-dropdown {
  display: block;

  padding: 5px;
  background-color: #fff;
  background-image: none;
  color: #4A4A4A;
  font-size: 16px;
  border: 1px solid #CCD6DD;
  box-shadow: none;
}

.header-wrapper .switch-language #switch-lang-dropdown:focus, #switch-country-dropdown:focus {
  border: 1px solid #01A4CE;
  outline : 0;
}

/* .header-wrapper .switch-language #switch-lang-dropdown {
  padding: 0;
  background-color: transparent;
  color: #ffffff;
  font-size: 25px;
  border: none; 
} */

/* 
.header-wrapper .switch-language #switch-lang-dropdown img {
  width: 30px;
  height: 30px; 
}

.header-wrapper .switch-language #switch-lang-dropdown:hover, .header-wrapper .switch-language #switch-lang-dropdown:active, .header-wrapper .switch-language #switch-lang-dropdown:focus {
  outline: none;
  border: none;
  box-shadow: none; }

.header-wrapper .switch-language #switch-lang-dropdown .flag-icon.mr {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  width: 1em;
  height: 1em;
  line-height: 1em;
  border-radius: 50%; }

.header-wrapper .switch-language #switch-lang-dropdown .caret {
  border-top: 6px dashed;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent; } */

.header-wrapper .title-language {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  margin-right: 10px;
  line-height: 1em;
  vertical-align: middle; }

.header-wrapper .company-name img {
  max-width: 250px;
  max-height: 100px; }
  

@media (max-width: 767px) {
  .header-box {
    padding: 0;
  }
}