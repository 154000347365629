@import url('https://fonts.googleapis.com/css2?family=Alata&family=Roboto:wght@300;400;500;700;900&display=swap');

:root {
  --main-color: #4A4A4A;
  --mobile-bg-color: #252830; 
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: #F7F9FB;
}

.form-action {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
}

.box {
  position: relative;
  padding: 20px;
  width: 500px;
  height: fit-content;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.box .box-form {
  position: relative;
  width: 100%;
  height: fit-content;
}

.left-col {
  padding-right: 10px;
}

.right-col {
  padding-left: 10px;
}

label {
  display: flex;
  margin-bottom: 5px;
  color: #4A4A4A;
}
/* 
input + label {
  display: inline-flex;
  margin-right: 20px;
} */

input[type='text'],
input[type='email'],
input[type='password'],
input[type='username'],
textarea {
  display: block;
  width: 100%;
  height: 39px;

  padding: 10px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #4A4A4A;
  background-color: #fff;
  background-image: none;
  border: 1px solid #CCD6DD;
  border-radius: 4px;

  /* Remove box-shadow on Safari */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select {
  display: block;
  width: 100%;
  height: 39px;

  padding: 8px 10px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #4A4A4A;
  background-color: #fff;
  background-image: none;
  border: 1px solid #CCD6DD;
  border-radius: 4px;
}

input:focus,
select:focus {
    border: 1px solid #01A4CE;
    outline : 0;
}

::placeholder {
  color: #BFBFC5;
}

select.select-box-placehover {
  color: #BFBFC5;
}

select option {
  color: #4A4A4A;
  border-radius: 4px;
}

select option:first {
  color: #BFBFC5;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, var(--main-color) 50%), linear-gradient(135deg, var(--main-color) 50%, transparent 50%) !important;
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em !important;
  background-size: 5px 6px, 5px 6px, 1px 1.5em !important;
  background-repeat: no-repeat !important;
  -webkit-appearance: none;
 }

.form-group {
  margin-bottom: 20px;
}

.form-group .error {
  border: 1px solid #FF6565;
}

.error-message {
  color: #FF6565;
  padding: .5em .2em;
  height: 1em;
  position: relative;
  font-size: .8em;
}

.register-btn {
  background-color: #B38806;
  color: #FAFAFA ;
  position: relative;

  /* border: 1px solid #B38806; */
  border: none;
  border-radius: 6px;

  width: 100%;
  height: 39px;
}

.register-btn:focus {
  outline: none;
  color: #BFBFC5 ;
}

.register-btn.disabled, 
.register-btn:disabled {
  background-color: #FAFAFA;
  border: 1px solid #CCD6DD;
  border-radius: 6px;
  color: #BFBFC5 ;
}

.register-btn span {
  font-size: 20px;
  font-weight: bold;
}

.textRighToLeft select { 
  background-position: calc(10px) calc(1em + 2px), calc(15px) calc(1em + 2px), calc(100% - 2.5em) 1.5em !important;
}

.textRighToLeft .error-message {
  direction: rtl;
}

.textRighToLeft #phone {
  padding: 10px 50px 10px 52px;
}

.textRighToLeft .iti-flag {
  right: 15px;
}

.selectSearch {
  
}