
.completed-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.completed-form .box-form h1 {
    font-size: 30px;
    font-weight: 600;
    color: #4A4A4A;
    margin-bottom: 30px;
}

.completed-form .box-form .fleetname-content {
    margin-top: 30px;
    font-size: 28px;
    font-weight: 800;
}

.completed-form .box-form .dowload_title {
    font-size: 20px;
    font-weight: 400;
}

.completed-form .box-form p {
    font-size: 20px;
    color: #8E8E93;
    margin-bottom: 20px;
}

.completed-form .box-form button {
    background-color: #B38806;
    color: #FAFAFA ;
    position: relative;
  
    border: none;
    border-radius: 6px;

    width: 100%;
    height: 50px;
    margin-top: 20px;
}

.completed-form .box-form button:focus {
    outline : 0;
}

.completed-form .box-form button span {
    font-size: 20px;
    font-weight: bold;
}

.qrcode_container { 
    margin-top: 20px;
}

.qrcode_container .qr_content {
    margin: 0 auto;
}

.qrcode_container .qr_content .qrcode {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.qrcode_container .qr_content .qrcode .img_qrcode {
    max-width: 250px;
}

.qrcode_container .qr_content .dowload_calculator {
    margin: 0 auto;
}

.qrcode_container .qr_content .dowload_calculator .bt_store {
    display: flex;
    justify-content: space-between;
}

.qrcode_container .qr_content .dowload_calculator .bt_store a {
    width: 47%;
}

.qrcode_container .qr_content .dowload_calculator .bt_store img {
    max-width: 100%;
    width: 100%;
}

@media (max-width: 767px) {
    .qrcode_container .qr_content .qrcode .img_qrcode {
        max-width: 150px;
    }
    .qrcode_container .qr_content .dowload_calculator .bt_store {
        justify-content: center;
    }
  }